import { throttle } from '../helpers.js';

$(function() {

    $('.accordion__item').on('accordionToggle', function () {
        var tables = $(this).find('.table-responsive');
        if (tables.length) {
            calcTableScroll(tables);
        }
    });

    // throttle window resize so it only fires every x ms, see throttle.js
    var debounceCalcTableScroll = throttle(function () {
        calcTableScroll($('.table-responsive'));
    }, 150);

    function calcTableScroll(elements) {

        if (elements && elements.length) {
            elements.each(function () {
                var $table = $(this).find('table');
                if ($table.outerWidth() > $(this).outerWidth()) {
                    $(this).addClass('has-scroll');
                } else {
                    $(this).removeClass('has-scroll');
                }
            });
        }
    }

    if ($('.table-responsive').length) {
        window.addEventListener('resize', debounceCalcTableScroll);
        calcTableScroll($('.table-responsive'));
    }

});