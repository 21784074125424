import {PagedNav} from "./paged-nav";

jQuery(function($) {

    const desktopNavEl = $('.paged-nav--desktop');
    const mobileNavEl = $('.paged-nav--mobile');

    if (desktopNavEl.length > 0) {
        let desktopNav = new PagedNav(desktopNavEl, 'desktop', 0);
    }

    if (mobileNavEl.length > 0) {
        let mobileNav = new PagedNav(mobileNavEl, 'mobile', mobileNavEl.attr('data-min-depth') === "-1" ? -1 : 0);
        mobileNav.cloneSecondaryMenu();
        mobileNav.mobileNavToggle();
    }

});