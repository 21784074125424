$(function() {


    $('.faq-search__form').on('submit', (e) => {

        var value = $('.faq-search__form input[type="search"]').val();
        // modify the action to pass the kw in the request
        e.target.action = `https://future.connect.curtin.edu.au/app/answers/list/kw/${encodeURI(value)}/page/1/session/`;
        return true;
    });

    $('.askcurtin-search__form').on('submit', (e) => {

        var value = $('.askcurtin-search__form input[type="search"]').val();
        // modify the action to pass the kw in the request
        e.target.action = `https://students.connect.curtin.edu.au/app/answers/list/kw/${encodeURI(value)}/page/1/session/`;
        return true;
    });

});
