// only the class should be in this file (as we @import it on other js files)
// see paged-nav-init for init

class PagedNav {

    constructor(container, navType='desktop', minDepth=0) {

        this.isMobile = navType !== 'desktop';
        this.isDesktop = navType === 'desktop';
        this.minDepth = minDepth;
        this.nav = container.find('.paged-nav-nav');
        this.buttons = container.find('.paged-nav-buttons');
        this.title = container.find('.paged-nav-title span');

        const site = this.nav.find('.current-site > a').attr('data-pn-id');
        this.siteTop = site ? this.nav.find(`ul[data-pn-id="${site}"]`) : false;

        if (navType === 'mobile') {
            this.top = this.nav.find('[data-pn-id="0"]');
        } else {
            let top = this.nav.find('.current-menu-item');

            if (!top.length) {
                top = this.nav.find('.current-menu-parent');
                if (!top.length) {
                    this.top = this.siteTop;
                }
            }

            this.top = top;
        }
        
        if (!this.top) return false;

        if (this.isDesktop) {
            // mobile will do this on mobileNavToggle()
            this.showInitialMenu();
        }


        this.nav.find('button[data-pn-id]').on('click', (e) => {
            const id = $(e.target).attr('data-pn-id');
            this.showMenu(this.nav.find(`ul[data-pn-id="${id}"]`));
            return false;
        });

        this.buttons.find('[paged-nav-first]').on('click', (e) => {
            this.showMenu(this.top, "back");
        });

        this.buttons.find('[paged-nav-prev]').on('click', (e) => {

            this.gotoPreviousPage();
        });

    }

    // check paged-nav-browse still works if changing this as it extends
    gotoPreviousPage() {
        // see if the current page is in the active menu


        if (this.isDesktop) {
            if (this.isInitialMenu()) {

                const href = this.nav.attr('data-pn-parent-link');

                // if it's  an applying page we want to load the parent not scroll back through paged nav
                if (href.indexOf('/applying/') !== -1) {
                    window.location = href;
                    return false;
                }
            }
        }

        const id = this.nav.find('.active').attr('data-pn-id');
        this.showMenu(this.findParentOfTrigger(id), "back");

    }

    cloneSecondaryMenu() {
        const secondaryMenu = $('.header__links ul li')
            .clone()
            .addClass('menu-item-secondary')
            .appendTo(this.top);
    }

    showInitialMenu(isFromToggle=false) {

        let currentItem = this.getInitialItem();
        let currentItemParent;

        if (currentItem.length > 0) {

            currentItem = currentItem.last();
            // if current page has children we want to show it's children menu
            if (currentItem.hasClass('menu-item-has-children')) {
                const id = currentItem.find('a').attr('data-pn-id');
                currentItemParent = this.nav.find(`ul[data-pn-id="${id}"]`);
            } else {
                // current page has no children
                currentItemParent = currentItem.parent('ul');
            }

            if (this.isDesktop) {
                currentItemParent.addClass('initial-menu');
            }
        }

        if (currentItemParent && currentItemParent.length > 0) {
            this.showMenu(currentItemParent, "forward", 0);
        } else {
            this.showTopMenu();
        }
    }

    // paged-nav-browse extends this function
    highlightCurrentItemParent(currentItemParent) {
        currentItemParent.find('li:first-child').addClass('current-menu-item');
    }

    getInitialItem() {
        let activeItem = this.nav.find('li.current-menu-item');

        if (activeItem.length === 0) {
            if (this.isMobile) {
                return this.siteTop ? this.siteTop : this.top;
            } else {
                return this.siteTop;
            }
        }

        return activeItem;
    }

    isInitialMenu() {
        return this.nav.find('.active.initial-menu').length === 1;
    }

    showTopMenu(recursed=false) {
        this.showMenu(this.getInitialItem(), false, 0, recursed);
    }

    showMenu(newActive, dir="forward", speed=200, recursed=false) {

        if (!newActive.length && recursed === false) {
            this.showTopMenu(true);
            return false;
        }

        if (!newActive.length) {
            return false;
        }

        const oldActive = this.nav.find('.active');

        this.nav.find('.previous')
            .removeClass('previous');

        newActive.addClass('current')
            .css('left', dir === 'forward' ? '100%' : '-100%')
            .animate({
                left: '0%'
            }, speed, "linear", () => {
                newActive.removeClass('current')
                    .addClass('active');

                this.showNavButtons(newActive);

                this.nav.find('.previous')
                    .removeClass('previous'); //

                // TODO improved to only trigger on keyboard events, :focus style
                // if user is interacting with menu (speed isn't 0), make it keyboard useable by setting focus)
                if (speed > 0) {
                    newActive.find('a').first()
                        .focus();
                }
            });

        if (this.isDesktop) {
            this.nav.animate({
                height: newActive.outerHeight()
            }, speed);
        }

        oldActive.removeClass('active')
            .addClass('previous')
            .css('left', '0%')
            .animate({
                left: dir === 'forward' ? '-100%' : '100%'
            }, speed, "linear");

        this.showMenuTitle(newActive);
    }

    showMenuTitle(newActive) {
        // WTB null coalescing operator
        this.title.html(newActive.attr('data-pn-parent-title') ? newActive.attr('data-pn-parent-title') : this.defaultMenuTitle());

        let newTitleLink = newActive.attr('data-pn-parent-link') ? newActive.attr('data-pn-parent-link') : false;
        const parentLink = this.title.parent('a');
        if (newTitleLink) {
            parentLink.attr('href', newTitleLink);
        } else {
            parentLink.removeAttr('href');
        }
    }

    findTriggerOfParent(id) {
        return this.nav.find(`a[data-pn-id="${id}"]`);
    }

    // find the parent ul of an ID
    findParentOfTrigger(id) {
        const idParent = this.findTriggerOfParent(id);
        return idParent.closest('ul');
    }

    resetClasses() {
        this.nav.find('.active, .current, .previous')
            .removeClass('active current previous');
    }

    showNavButtons(newActive) {
        const depth = newActive.attr('data-pn-depth');
        const first = this.buttons.find('[paged-nav-first]');
        const prev =  this.buttons.find('[paged-nav-prev]');

        if (depth && depth <= this.minDepth) {
            first.prop('disabled', true);
            prev.prop('disabled', true);
            this.hideButton(first);
            this.hideButton(prev);
        } else {
            this.showButton(first);
            this.showButton(prev);
        }

        if (depth > (this.minDepth + 1)) {
            this.showButton(first);
        } else {
            this.hideButton(first);
        }

    }

    hideButton(button) {
        button.prop('disabled', 'disabled');
    }

    showButton(button) {
        button.prop('disabled', false)
            .removeClass('invisible');
    }

    defaultMenuTitle() {
        return 'Menu';
    }

    mobileNavToggle() {
        $('[device-nav-toggle]').on('click', (e) => {
            $('body').toggleClass('device-nav-active');

            if ($('body').hasClass('device-nav-active')) {
                this.resetClasses();
                this.showInitialMenu(true);
            }
        });
    }
}

export { PagedNav }