document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

import './helpers.js';
import './header.js';
import './src/paged-nav.js';
import './src/paged-nav-init.js';
import './src/accordion.js';
import './src/autocomplete.js';
import './src/card-spots.js';
import './src/dropdown.js';
import './src/kadence.js';
import './src/fancybox.js';
import './src/forms-modal.js';
import './src/nice-select.js';
import './src/personalisation.js';
import './src/search-form-submit.js';
import './src/slick-sliders.js';
import './src/support.js';
import './vendor/slick.min.js';
import './src/table-responsive.js';