import {throttle} from "throttle-debounce";

(function($) {

    function mastheadSliderInit() {

        $('.masthead-slider__stage').each(function() {

            // using an id allows multiple instances on a page
            let sliderId = $(this).attr('data-slider-id');

            $(this).removeClass('preload')
                .slick({
                    dots: true,
                    appendDots: '#m-slider--' + sliderId + ' .masthead-slider__controls',
                    arrows: false,
                    adaptiveHeight: false,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    fade: true,
                    cssEase: 'linear',
                    pauseOnHover: true,
                    touchThreshold: 20
                });

        });
    }

    function cardMediaSliderInit() {
        let hash = window.location.hash ? window.location.hash.substring(1) : false;

        $('.card-ms').each(function() {
            let slickId = '#' + $(this).find('.card-ms__stage').attr('id');
            let hashSlide = 0;

            // if theres are .cards matching #, set the slider to that .card
            if (hash) {
                $(this).find('.slide__card .card').each(function() {
                    let cardId = ($(this).attr('id')) ?? false;
                    if (cardId && cardId === hash) {
                        hashSlide = $(this).parents('.card-ms__slide').index();
                        window.scrollTo(0, 0);
                    }
                });
            }

            $(slickId).on('init', function(event, slick) {
                let card = $('.card-ms .card-ms__stage .slick-slide.slick-active');
                let ctrl = card.find(`.slide__controls ul li:nth-child(${hashSlide+1})`);
                ctrl.addClass('active');
            });

            $(slickId).slick({
                dots: true,
                autoplay: hashSlide ===0,
                appendDots: slickId + ' .slide__controls',
                arrows: false,
                adaptiveHeight: true,
                fade: true,
                cssEase: 'linear',
                pauseOnHover: true,
                touchThreshold: 20,
                autoplaySpeed: 6000,
                initialSlide: hashSlide
            });



            // Before slide change
            $(slickId).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                // de-activate highlighted dot
                $(slickId + ' .slide__controls ul li.active').removeClass('active');
                $(this).find('.slide__card ul li:nth-child(' + (nextSlide + 1) + ')').addClass('active');
            });

        });
    }

    function actionSliderInit() {


        $('.action-panel[data-slider-active="true"]').each(function () {
            let $actionSlider = $(this).find('.action-panel__slider .card-grid');

            $actionSlider.slick({
                slidesToShow: 1,
                arrows: false,
                dots: true,
                appendDots: $(this).find('.slider-controls'),
                fade: true,
                cssEase: 'linear',
                rows: 2,
                slidesPerRow: 1,
            });
        });
    }

    function imageSliderInit() {
        $('.image-slider').each(function() {
            let slickId = '#' + $(this).find('.image-slider__stage').attr('id');

            $(slickId).slick({
                arrows: false,
                dots: true,
                appendDots: slickId + ' + .slider-controls',
                fade: true,
                cssEase: 'linear',
            });
        });
    }

    // custom slide advance
    function nextStory($this, nextSlide){
        let $story_block = $this.closest('.stories');
        // let i = $this.data('slick-index');
        // make this the active slide
        $story_block.find('.stories__pics, .stories__stage').slick('slickGoTo',nextSlide);
        // advance thumbnails +1 to hide active slide for large screens,
        nextSlide = $(window).width() >= 1024 ? nextSlide+1 : nextSlide;
        $story_block.find('.stories__nav').slick('slickGoTo',(nextSlide));
    }

    // stories block
    function storySliderInit() {

        $('section.stories').each(function() {
            let $this = $(this).find('.stories__container');
            let story_count = $this.data('story-count') -1;// one less so it scrolls correctly,;
            let $nav_controls = $this.find('.stories__nav');

            // the slides
            $this.find('.stories__stage').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '',
                nextArrow: '<button class="slick-next" paged-nav-next aria-label="Next story"></button>',
                appendArrows: $this.find('.stories__nav-controls'),
                dots: false,
                fade: true,
                rows: 0
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                nextStory($(this),nextSlide);
            });

            // the navigation thumbnails
            $nav_controls.slick({
                slidesToShow: story_count,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: false,
                draggable: false,
                variableWidth: true,
                rows: 0
            });

            // the navigation thumbnails
            $this.find('.stories__pics').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                infinite: true,
                rows: 0,
            }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                nextStory($(this),nextSlide);
            });

            $(window).on('resize', throttle(300, function() {
                resizeStoriesPics();
            }));

            resizeStoriesPics();

            function resizeStoriesPics() {
                let pics = $this.find('.stories__pics')
                pics.slick('slickUnfilter');
                pics.slick('slickFilter',  '.stories__pic-wrap--'  + ($(window).width() >= 1024 ?  'desktop' : 'mobile'));
            }
        });

        $('.stories .slick-next').on('click', function(){
            // next button already slides the story stage,
            // slide the pic and thumbnails too
            $(this).closest('.stories').find('.stories__nav, .stories__pics').slick('slickNext');
        });

        $('.stories__nav img').click(function(){
            nextStory($(this), $(this).data('slick-index'));
        })

    }

    function testimonialSliderInit() {
        $('.testimonial-slider .testimonial__container').each(function() {
            let slickId = '#' + $(this).attr('id');

            $(slickId).slick({
                dots: true,
                appendDots: slickId + ' + .testimonial-slider__controls',
                arrows: false,
                adaptiveHeight: false,
                autoplay: true,
                autoplaySpeed: 5000,
                fade: true,
                cssEase: 'linear',
                pauseOnHover: false,
                touchThreshold: 20
            });
        });
    }

    // set height for all items to tallest item
    function setItemHeight() {
        let tallest = 300;

        $('.stories__content-container').each(function() {
            let this_height = $(this).outerHeight();
            // is this height greater than current tallest height?
            tallest = this_height > tallest ? this_height : tallest;
        }).css('min-height', (tallest + 'px'));
    }

    // do this before slick is init for stories
    if($('.block.stories').length) {
        setItemHeight();
    }

    $(document).ready(function() {
        if($('.masthead-slider').length) {
            mastheadSliderInit();
        }

        if($('.card-ms__slide').length) {
            cardMediaSliderInit();
        }

        if($('.image-slider__slide').length) {
            imageSliderInit();
        }

        if($('.block.stories').length) {
            storySliderInit();
        }


        if($('.testimonial-slider').length) {
            testimonialSliderInit();
        }

        if($('.action-panel[data-slider-active="true"]').length){
            actionSliderInit();
        }

        // match window to 768px width, returns true or false.
        const mq = window.matchMedia("(max-width: 768px)");
        mq.addListener(pauseSlider);

        // if window width less than 768 pause slick slider, else play slider.
        function pauseSlider(mq) {
            if(mq.matches) {
                $('.card-ms__stage').slick('slickPause');
            } else {
                $('.card-ms__stage').slick('slickPlay');
            }
        }
    });

})(jQuery);